import React, { FunctionComponent } from "react";

type Props = {
  title?: string;
};
export const FeatureSection: FunctionComponent<Props> = ({
  title,
  children,
}) => {
  return (
    <div className="max-w-4xl mx-auto mb-16 flex flex-col gap-4">
      <h2 className="text-3xl leading-tight text-red-to-yellow">{title}</h2>
      {children}
    </div>
  );
};
