import React, { FunctionComponent } from "react";

type Props = {
  title?: string;
  subtitle?: string;
};
export const HomePageSection: FunctionComponent<Props> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <section className="py-md md:py-md container text-lg">
      <div className="max-w-2xl mx-auto items-center text-center">
        <div className="mb-2 text-sm font-bold uppercase tracking-wider text-red-to-yellow">
          {subtitle}
        </div>
        <h2 className="text-4xl leading-tight">{title}</h2>
      </div>
      {children}
    </section>
  );
};
