import React, { FunctionComponent } from "react";

type Props = {
  quote: string;
  name: string;
  title: string;
  link: string;
};
export const TestimonialBlock: FunctionComponent<Props> = ({
  quote,
  name,
  title,
  link,
}) => {
  return (
    <div className="bg-gray-750 p-6 rounded-lg">
      <a href={link}>
        <blockquote className="italic text-gray-300 text-lg mb-4">
          {quote}
        </blockquote>
      </a>

      <div>
        <p className="text-base text-gray-300 font-semibold">{name}</p>
        <p className="text-sm text-gray-400">{title}</p>
      </div>
    </div>
  );
};
